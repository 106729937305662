import { useQuery, useQueryClient, useMutation } from 'react-query'
import {
  getSupplierHistory,
  getSuppliersData,
  rejectPiiValidationManually,
  resendSupplierInvitation,
  updateSupplierEmail
} from '../services/apiPII'

export const useGetSuppliersData = (pagination, sorting, filters) => {
  filters = filters.sort((a, b) => {
    const keyA = `${a.fieldName}|${a.operatorValue}|${a.filterValue}`
    const keyB = `${b.fieldName}|${b.operatorValue}|${b.filterValue}`
    return keyA.localeCompare(keyB)
  })
  const queryKey = [
    'suppliers',
    `pageSize|${pagination.pageSize}`,
    `pageNumber|${pagination.pageNumber}`,
    `sortField|${sorting?.field || 'NA'}`,
    `sortDirection|${sorting?.sort || 'NA'}`,
    `filters|${filters.map(f => `${f.fieldName}:${f.operatorValue}:${f.filterValue}`).join('|') || 'NA'}`
  ]
  return useQuery(queryKey, () => getSuppliersData(pagination, sorting, filters), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 1 * 30 * 1000
  })
}

export const useGetSupplierHistory = (tenantId, appId, supplierId) => {
  const queryKey = ['suppliers', `${tenantId} ${appId}`, `${supplierId}`]
  return useQuery(queryKey, () => getSupplierHistory(tenantId, appId, supplierId), {
    refetchOnWindowFocus: false
  })
}

export const useModifySupplier = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ hashKey, validationId, email }) => {
      return updateSupplierEmail(hashKey, validationId, email)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('suppliers')
      }
    }
  )
}

export const useRejectPiiValidationManually = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ hashKey, validationId, rejectedMessage }) => {
      return rejectPiiValidationManually(hashKey, validationId, rejectedMessage)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('suppliers')
      }
    }
  )
}

export const useResendInvitation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ hashKey, validationId }) => {
      return resendSupplierInvitation(hashKey, validationId)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('suppliers')
      }
    }
  )
}
