import React, { useEffect, useState } from 'react'
import { Prompt } from 'react-router-dom'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'

import { CircularProgress } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import AddAlert from '@material-ui/icons/AddAlert'
import { useQueryClient } from 'react-query'
// @material-ui/icons
import AssessmentIcon from '@material-ui/icons/Assessment'
import { v4 as uuidv4 } from 'uuid'

import MailOutlineIcon from '@material-ui/icons/MailOutline'

import TouchApp from '@material-ui/icons/TouchApp'
// components
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import MaterialTable from 'material-table'

// style
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/nitorInsightsStyle.js'

// Constants
import Snackbar from 'components/Snackbar/Snackbar'
import { useGetAppList } from 'hooks/useApp'
import {
  useCreateRejectConfiguration,
  useGetRejectConfiguration,
  useUpdateRejectConfiguration
} from 'hooks/useInvoiceConfig'

const useStyles = makeStyles(styles)

export default function InvoiceRejectConfiguration(props) {
  const appName = 'invoiceAI'
  const classes = useStyles()
  const [selectedAppId, setSelectedAppId] = useState(props.match.params.id)

  const { data: appList, isLoading: isLoadingAppList } = useGetAppList(appName)

  const rejectConfiguration = useGetRejectConfiguration(selectedAppId)

  const [isCreate, setIsCreate] = useState(false)

  const queryClient = useQueryClient()
  const [data, setData] = useState([])
  const createRejectConfiguration = useCreateRejectConfiguration()
  const updateRejectConfiguration = useUpdateRejectConfiguration()
  const [showMessage, setShowMessage] = useState({ isOpen: false, message: '', color: '' })
  const [allowEdition, setAllowEdition] = useState(false)
  const [isFormSaved, setFormSaved] = useState(true)
  useEffect(() => {
    if (rejectConfiguration.isLoading) {
      return
    }
    if (rejectConfiguration.isError) {
      setShowMessage({ isOpen: true, message: 'Something went wrong', color: 'danger' })
      return
    }
    if (rejectConfiguration.data) {
      setData(rejectConfiguration.data.options)
      setAllowEdition(rejectConfiguration.data.allowEdition)
    } else {
      setIsCreate(true)
    }
  }, [rejectConfiguration.isLoading, rejectConfiguration.isError, rejectConfiguration.data])

  let breadcrumbViews = [
    {
      name: 'Invoice AI',
      url: '/admin/invoiceAI/configurations',
      icon: AssessmentIcon
    },
    {
      name: 'Invoice Rejection Reasons',

      url: `/admin/invoiceAI/additionalConfigurations/reject/${selectedAppId}`,
      icon: MailOutlineIcon
    }
  ]

  const columns = [
    {
      title: 'id',
      field: 'id',
      hidden: true
    },
    {
      field: 'reason',
      title: 'Rejection Reason'
    },
    {
      field: 'message',
      title: 'Message'
    }
  ]

  const onAppChanged = id => {
    setSelectedAppId(old => {
      if (old !== id) {
        setAllowEdition(false)
        setData([])
        setFormSaved(true)
        props.history.push(`/admin/invoiceAI/additionalConfigurations/reject/${id}`)
      }
      return id
    })
  }

  function onSave() {
    if (isCreate) {
      createRejectConfiguration.mutate(
        {
          appId: selectedAppId,
          body: { options: data.map(({ tableData, ...row }) => row), allowEdition }
        },
        {
          onSuccess: () => {
            setShowMessage({
              isOpen: true,
              message: 'Configuration saved',
              color: 'success'
            })
            setFormSaved(true)
          },
          onSettled: () => {
            queryClient.invalidateQueries(['Apps', selectedAppId, 'reject', 'configuration'])
          },
          onError: error => {
            setShowMessage({
              isOpen: true,
              message: error.response?.data?.message ?? 'Something went wrong, try again later',
              color: 'danger'
            })
          }
        }
      )
    } else {
      updateRejectConfiguration.mutate(
        {
          appId: selectedAppId,
          body: { options: data.map(({ tableData, ...row }) => row), allowEdition }
        },
        {
          onSuccess: () => {
            setShowMessage({
              isOpen: true,
              message: 'Configuration saved',
              color: 'success'
            })
            setFormSaved(true)
          },
          onSettled: () => {
            queryClient.invalidateQueries(['Apps', selectedAppId, 'reject', 'configuration'])
          },
          onError: error => {
            setShowMessage({
              isOpen: true,
              message: error.response?.data?.message ?? 'Something went wrong, try again later',
              color: 'danger'
            })
          }
        }
      )
    }
  }

  const toggleChecked = () => {
    setAllowEdition(prev => !prev)
  }

  if (isLoadingAppList) {
    return (
      <Card>
        <GridContainer justifyContent="center" alignItems="center">
          <div className={classes.circularProgress}>
            <CircularProgress color="inherit" />
          </div>
        </GridContainer>
      </Card>
    )
  }

  const isLoadingData = Boolean(
    rejectConfiguration.isLoading ||
      createRejectConfiguration.isLoading ||
      updateRejectConfiguration.isLoading
  )

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} lg={3}>
        <Card className={classes.appBar}>
          <CardHeader>Application List</CardHeader>
          <CardBody>
            <List>
              {appList
                ? appList.map((prop, key) => {
                    return (
                      <ListItem
                        className={classes.listItem}
                        button
                        selected={selectedAppId === prop.env}
                        key={key}
                        onClick={() => onAppChanged(prop.env)}
                      >
                        <ListItemAvatar>
                          {selectedAppId === prop.env ? (
                            <Avatar>
                              <TouchApp />
                            </Avatar>
                          ) : (
                            <Avatar className={classes.clearAvatar}></Avatar>
                          )}
                        </ListItemAvatar>
                        <ListItemText primary={prop.appName} secondary={`Type: ${prop.type}`} />
                      </ListItem>
                    )
                  })
                : null}
            </List>
          </CardBody>
        </Card>
      </GridItem>

      <GridItem xs={12} sm={12} md={12} lg={9}>
        <Breadcrumbs views={breadcrumbViews} />
        <Card>
          <CardHeader>
            <h3 className={classes.formHeader}>Invoice AI - Rejection Reason Configuration</h3>
          </CardHeader>
          <CardBody>
            <FormControlLabel
              control={<Switch checked={allowEdition} onChange={toggleChecked} color="primary" />}
              label="Allow user to edit message"
            />
            <Button size="small" onClick={onSave}>
              Save Changes
            </Button>
            <MaterialTable
              columns={columns}
              data={data}
              isLoading={isLoadingData}
              editable={{
                onRowAdd: newData =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      setData([...data, { ...newData, id: uuidv4() }])
                      setFormSaved(false)
                      resolve()
                    }, 0)
                  }),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      const dataUpdate = [...data]
                      const index = oldData.tableData.id
                      dataUpdate[index] = newData
                      setData([...dataUpdate])
                      setFormSaved(false)
                      resolve()
                    }, 0)
                  }),
                onRowDelete: oldData =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      const dataDelete = [...data]
                      const index = oldData.tableData.id
                      dataDelete.splice(index, 1)
                      setData([...dataDelete])
                      setFormSaved(false)
                      resolve()
                    }, 0)
                  })
              }}
              options={{
                actionsColumnIndex: -1,
                sorting: true,
                showTitle: false,
                emptyRowsWhenPaging: false
              }}
            />
          </CardBody>
        </Card>
      </GridItem>
      <Snackbar
        place="bl"
        color={showMessage.color}
        icon={AddAlert}
        message={showMessage.message}
        open={showMessage.isOpen}
        closeNotification={() => setShowMessage({ isOpen: false, message: '' })}
        close
      />
      <Prompt
        when={!isFormSaved} // Show prompt only if form is incomplete
        message="You have unsaved changes. Are you sure you want to leave?"
      />
    </GridContainer>
  )
}
